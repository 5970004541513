/*.login-page {
  input {
    box-sizing: border-box;
  }

  input:focus,
  textarea:focus {
    outline: none;
  }

  input {
    margin-bottom: 1rem;
    margin-top: 0.0rem;
    padding: 0px 10px 0px 10px;
    width: 100%;
    border: none;
    background: #f1f1f1;
  }

  label {
    padding: 0px 0px 0px 00px;
    margin-bottom: 0rem;
    pointer-events: none;
    user-select: none;
    opacity: 1;
    font-size: 0.7rem;
    background: #f1f1f1;
    width:100%;
  }

  input:focus ~ label {
    user-select: none;
  }
}*/


.login-page {
  input {
    box-sizing: border-box;
  }

  input:focus,
  textarea:focus {
    outline: none;
  }


  input {
    margin-bottom: 1rem;
    margin-top: 0.2rem;
    padding: 10px 10px 10px 10px;
    width: 100%;
    border: 1px solid #D9D9D9;
    //background: #f3f3f3;
  }

  label {
    margin-bottom: 0rem;
    pointer-events: none;
    user-select: none;
    opacity: 1;
    font-size: 0.7rem;
  }

  input:focus ~ label {
    user-select: none;
  }
}
